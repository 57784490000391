import React from "react";
import HeadSeo from "../components/HeadSeo.js";
import Layout from "../components/Layout.js";
import IntroBlock from "../components/IntroBlock.js";
import FetchNewsBlock from "../components/FetchNewsBlock";
import BlogLists from "../components/BlogLists.js";
import Default from "../_contenthub/website.json";
const { introBlockNewsHome, introBlockBlogHome, myContentInfo } = Default;

export const Head = () => {
  return (
    <HeadSeo
      title={`Home`}
      description={`Welcome to our ${myContentInfo.websiteTitle} where we are excited to offer you a wide variety of resources to deepen your understanding and share information with others. We offer a range of opinions based on years of experience.`}
      seoImg={`intro-block.jpg`}
    ></HeadSeo>
  );
};

const IndexPage = () => {
  return (
    <>
      <Layout>
        <main>
          <div className="main-wrapper">
            <div className="primary-wrapper">
              <div className="layout-full-homepage">
                <div className="main main-homepage">
                  <div className="top-home">
                    <IntroBlock />
                  </div>

                  <div className="left-home">
                    <div className="intro-wrap intro-wrap-accent-1">
                      <h3 className="intro-section">
                        {introBlockBlogHome.title}
                      </h3>
                      <p className="intro-section">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: introBlockBlogHome.text,
                          }}
                        />
                      </p>
                    </div>
                    <BlogLists limit="9" layoutType={`grid-top`} />
                  </div>

                  <div className="right-home">
                    <div className="intro-wrap intro-wrap-accent-2">
                      <h3 className="intro-section">
                        {introBlockNewsHome.title}
                      </h3>
                      <p className="intro-section">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: introBlockNewsHome.text,
                          }}
                        />
                      </p>
                    </div>

                    <FetchNewsBlock limit="3" layoutType="mini-news" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default IndexPage;
