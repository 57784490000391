import React, { Component } from "react";

import Default from "../_contenthub/website.json";
const { introBlockHome, myContentInfo } = Default;

class IntroBlock extends Component {
  render() {
    if (!introBlockHome) return null;

    const item = introBlockHome;

    return (
      <div className={`block-layout block-layout--home-intro`}>
        <div className={`element-data`}>
          <div className="el">
            <div className="img">
              {item?.img ? (
                <img
                  src={item.img}
                  loading="lazy"
                  alt={myContentInfo.shortName}
                />
              ) : (
                ``
              )}
            </div>
            <div className="content">
              <div className="title">
                <h1>
                  {item?.title} <span>{myContentInfo.shortName}</span>
                </h1>
              </div>
              <div className="text">{item.text}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IntroBlock;
